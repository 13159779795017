import axios from 'axios';

const baseUrl = '';
const apiBaseUrl = '/api/';

export const UserService = {
    async getEmail() {
        return axios.get(`${baseUrl}/getEmail`)
    },

    async checkNIPAvailability(nip) {
        return axios.get(`${apiBaseUrl}nip-available/${nip}`)
    },
    async saveData(data) {
        return axios.post(`${baseUrl}/saveCompany`, data)
    },
    async checkPostalCode(postalCode) {
        return axios.get(`${apiBaseUrl}zipcode-validate/${postalCode}`)
    }
};

export const OrderService = {
    async calculatePrice({A, S, shipment, perAutoclave}) {
        return axios.post(`${baseUrl}/calculate-price`, {A, S, shipment, perAutoclave})
    },
    async priceTable() {
        return axios.get(`${baseUrl}/pricing`)
    },
    async getCompanyAddress() {
        return axios.get('/get-company-details')
    },
    async makePayment(orders) {
        return axios.post('/make-payment', {orders})
    },
    async getDecontaminationPeriods(){
        return axios.get('/decontamination-periods');
    }
}

export const AccountService = {
    async getTests(page = 1, dateFrom = null, dateTo = null) {
        const params = {
            page
        }

        if (dateFrom) {
            params.date_from = dateFrom
        }

        if (dateTo) {
            params.date_to = dateTo
        }

        return axios.get(`/user-tests`, {
            params
        })
    },
    async getUserInvoices(currentPage = 0) {
        return axios.get(`/user-invoices`, {
                params: {
                    'currentPage': currentPage,
                }
        })
    },
    async downloadInvoice(invoiceId) {
        return axios.get(`/user-invoices/${invoiceId}`)
    },
    async logout(routeName) {
        return axios.post(routeName);
    },
    async sendTestsReports(dateFrom, dateTo, email = null) {
        const params = {
            start_date: dateFrom,
            end_date: dateTo
        }

        if (email) {
            params.email = email
        }

        return axios.get('/generate-report-package');
    },
    async getUserData() {
        return axios.get('/get-company-details')
    },
    async getUserAccountData() {
        return axios.get('/get-account-details')
    },
    async editUserData(newUserData) {
        return axios.post('/update-company-details', {...newUserData})
    },
    async changePassword(passwords) {
        return axios.post('/save-password', {...passwords})
    },
    async getAutoclaves() {
        return axios.get(`/addresses`)
    },
    async addAutoclave(type, autoclaveUuid) {
        return axios.post(`/addresses/${autoclaveUuid}/autoclave`, {type})
    },
    async editAutoclave(uuid, data) {
        return axios.put(`/addresses/${uuid}`, {...data});
    },
    async updateAutoclaveToDeleteStatus(addressUuid, autoclaveId, status) {
        return axios.put(`/addresses/${addressUuid}/autoclave/${autoclaveId}`, {to_delete: status})
    },
    async deleteAutoclave(autoclaveId) {
        return axios.delete(`/autoclaves/${autoclaveId}`)
    },
    async deleteAddress(uuid){
        return axios.delete(`/addresses/${uuid}`)
    }
}

export const InpostService = {
    async getParcelLockerData(parcelLocker) {
        return axios.get(`
        https://api-pl-points.easypack24.net/v1/points/${parcelLocker}`)
    }
}
